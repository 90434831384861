import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { signUp } from "../actions/signUp";
import { checkCustomer } from "../actions/customer";
import { parse } from 'query-string';
import validator from "validator";
import Notifications, {notify} from 'react-notify-toast';
import Loader from "../common/Loader";
import config from "../config"

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            mobile: "",
            errors: {},
            isSignUp: false
        }
    }

    componentWillMount(){
        const { redirect_url, client_code, is_staff } = parse(this.props.location.search);
        let hash = this.props.location.hash ? this.props.location.hash : "";
        let url = `${redirect_url}${hash}`;
        console.log("this.props.location.state::",this.props.location.state)
        this.setState({ firstname: this.props.location.state ? this.props.location.state.firstname : ""
        ,lastname:this.props.location.state ? this.props.location.state.lastname : ""
        ,email:this.props.location.state ? this.props.location.state.email : ""});
       
        // if (redirect_url) {
        //     localStorage.setItem("redirect_url", url);
        // }
        // if (client_code) {
        //     localStorage.setItem("client_code", client_code);
        // }
        // if (is_staff) {
        //     localStorage.setItem("is_staff", is_staff);
        // }
    }

    handleFirstnameChanged = (e) => {
        this.setState({ firstname: e.target.value });
    }

    handleLastnameChanged = (e) => {
        this.setState({ lastname: e.target.value });
    }

    handleEmailChanged = (e) => {
        this.setState({ email: e.target.value });
    }

    _signUp = () => {
        const { redirect_url, client_code, is_staff } = parse(this.props.location.search);

        // let redirect_url = localStorage.getItem("redirect_url");
        // let client_code = localStorage.getItem("client_code");
        const { otp } = this.props;
        const firstname = this.state.firstname;
        const lastname =  this.state.lastname;
        const email = this.state.email;
        let errors = {};
        if (!firstname) {
            errors.firstname = "First name is required";
        }
        // if (!lastname) {
        //     errors.lastname = "Last name is required";
        // }
        if (!email) {
            errors.email = "Email is required";
        } else if (!validator.isEmail(email)) {
            errors.email = "Invalid email address";
        }

        this.setState({ errors });
        if (Object.keys(errors).length  === 0) {
            let requestBody = {};
            let mobile = this.props.location.state ? this.props.location.state.mobile : "";
            requestBody.first_name = firstname;
            requestBody.last_name = lastname;
            requestBody.phonenumber = mobile;
            requestBody.email = email;
            this.setState({ isSignUp: true });
            this.props.checkCustomer({ token: otp.result.token, client_code, email}).then((checkCustomerResponse, CheckCustomerError) => {
                if (!CheckCustomerError) {
                    if (checkCustomerResponse.code === 200) {
                        this.setState({ isSignUp: false });
                        notify.show(checkCustomerResponse.result, "error", 3000, "red")
                    } else if (checkCustomerResponse.code === 204){
                        this.props.signUp({ token: otp.result.token, client_code, requestBody }).then((response, error) => {
                            this.setState({ isSignUp: false });
                            if (!error) {
                                var cookieName = 'token';
                                var cookieValue = otp.result.token;
                                let cookieClient_code = "client_code";
                                let cookieClient_code_value = client_code;
                                var date = new Date();
                                let cookieSID = "SID";
                                let cookieSIDValue = otp.result.session_id;                        
                            
                                date.setMonth(date.getMonth() + 12);
                                document.cookie = cookieName +"=" + cookieValue + ";expires=" + date 
                                                + ";domain="+config.domain+";path=/";
                                document.cookie = cookieClient_code +"=" + cookieClient_code_value + ";expires=" + date 
                                                + ";domain="+config.domain+";path=/";
                                document.cookie = cookieSID +"=" + cookieSIDValue + ";expires=" + date 
                                                + ";domain="+config.domain+";path=/";
                        
                        //----------- Setting up local Domain -------------- //
                                let subdomain = redirect_url.split("//")[1].split("/")
                                //console.log("subdomain::::::",subdomain)
                                document.cookie = cookieName +"=" + cookieValue + ";expires=" + date 
                                + ";domain="+subdomain[0]+";path=/";
                                document.cookie = cookieClient_code +"=" + cookieClient_code_value + ";expires=" + date 
                                + ";domain="+subdomain[0]+";path=/";
                                                                        
                                
                                window.open(redirect_url,'_self');
                            } else {
                                notify.show(error.result, "error", 3000, "red")
                            }
                        });
                    } else {
                        this.setState({ isSignUp: false });
                        notify.show(checkCustomerResponse.message, "error", 3000, "red")
                    }
                } else {
                    this.setState({ isSignUp: false });
                }
            });
        }
    }

    render() {
        const {handleSubmit } = this.props;
        let mobile = this.props.location.state ? this.props.location.state.mobile : "";
        let is_registration = this.props.location.state ? this.props.location.state.is_registration : true;
    
        return (
            <div className="formWidget">
                <Notifications/>
                <article>
                    <h4>welcome to</h4>
                    <h2>Caring Pharmacy</h2>
                </article>
                <form onSubmit={handleSubmit(this._signUp)}>
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="firstname" name="firstname" className="form-control" value={this.state.firstname} onChange={this.handleFirstnameChanged} />
                        { this.state.errors && this.state.errors.firstname && <span className="validationError">{this.state.errors.firstname}</span> }
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="lastname" name="lastname" className="form-control" value={this.state.lastname} onChange={this.handleLastnameChanged} />
                        {/* { this.state.errors && this.state.errors.lastname && <span className="validationError">{this.state.errors.lastname}</span> } */}
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" name="email" className="form-control" value={this.state.email} onChange={this.handleEmailChanged} />
                        { this.state.errors && this.state.errors.email && <span className="validationError">{this.state.errors.email}</span> }
                    </div>
                    <div className="form-group">
                        <label>Mobile</label>
                        <input type="mobile" name="mobile" className="form-control" disabled value={mobile}/>
                    </div>
                    <div className="form-group">
                        { is_registration && <button type="submit" className="btn btn-default">SignUp</button>}
                        { !is_registration && <button type="submit" className="btn btn-default">Update Profile</button>}
                    </div>
                </form>
                <Loader loader={this.state.isSignUp}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        payload: state.signUp.payload,
        otp: state.otp.payload
    }
}

const enhance = compose(
    reduxForm({
        form: 'signUp',
        validate: (values, props) => {
            const errors = {};
            if (!values.firstname) {
                errors.firstname = "First name is required";
            }
            if (!values.lastname) {
                errors.lastname = "Last name is required";
            } 
            if (!values.email) {
                errors.email = "Email is required";
            }
            if (!values.mobile) {
                errors.mobile = "Mobile is required";
            }
            return errors;
        },
    }),
    connect(
        mapStateToProps,
        { signUp, checkCustomer }
    )
);

export default enhance(SignUp);
