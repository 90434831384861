import { CUSTOMER_FETCH_SUCCESS, CUSTOMER_FETCH_FAILED, CHECK_CUSTOMER_SUCCESS, CHECK_CUSTOMER_FAILED } from "../constants/ActionTypes";
import axios from "axios";
import config from "../config";

const customerFetchSuccess = (payload) => (dispatch) => {
    dispatch({
        type: CUSTOMER_FETCH_SUCCESS,
        customer: payload
    })
}

const customerFetchFailed = (error) => (dispatch) => {
    dispatch({
        type: CUSTOMER_FETCH_FAILED,
        error
    })
}

export const getCustomer = ({ token, client_code }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.base_url}/customer`,
        {
            headers: {
                "Content-Type": "application/json",
                "x-user-token": token,
                "client_code": client_code
            }
        }).then((response) => {
            if (response.status === 200 && response.data.code === 200) {
                dispatch(customerFetchSuccess(response.data));
                resolve(response.data);
            } else if (response.status === 200 && response.data.code === 204) {
                dispatch(customerFetchSuccess(response.data));
                resolve(response.data);
            } else {
                dispatch(customerFetchFailed(response.data));
                reject(response.data.message);
            }
        }).catch((error) => {
            dispatch(customerFetchFailed(error));
            reject(error);
        });
    })
}


const checkCustomerSuccess = (payload) => (dispatch) => {
    dispatch({
        type: CHECK_CUSTOMER_SUCCESS,
        checkCustomer: payload
    })
}

const checkCustomerFailed = (error) => (dispatch) => {
    dispatch({
        type: CHECK_CUSTOMER_SUCCESS,
        checkCustomerError: error
    })
}

export const checkCustomer = ({ token, client_code, email }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.base_url}/customer/check`,
        {
            headers: {
                "Content-Type": "application/json",
                "x-user-token": token,
                "client_code": client_code,
                "email": email
            }
        }).then((response) => {
            if (response.status === 200) {
                dispatch(checkCustomerSuccess(response.data));
                resolve(response.data);
            }
        }).catch((error) => {
            dispatch(checkCustomerFailed(error));
            alert(error);
        });
    })
}