import { LOGIN_SUCCESS, LOGIN_FAILED, AUTHENTICATION_CHECK_SUCCESS, AUTHENTICATION_CHECK_FAILED } from "../constants/ActionTypes";

const INITIAL_STATE = { payload: "", error: "", authenticationCheckError: "", authenticationCheckSuccess: "" };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS: {
            return {...state, payload: action.payload }
        }
        case LOGIN_FAILED: {
            return {...state, error: action.error }
        }
        case AUTHENTICATION_CHECK_SUCCESS: {
            return { ...state, authenticationCheckSuccess: action.authenticationCheckSuccess }
        }
        case AUTHENTICATION_CHECK_FAILED: {
            return { ...state, authenticationCheckError: action.authenticationCheckError }
        }
        default:
            return state
    }
}
