import { SEND_OTP_SUCCESS, SEND_OTP_FAILED, OTP_SUCCESS, OTP_FAILED } from "../constants/ActionTypes";
import config from "../config";
import axios from "axios";

const sendOtpSuccess = (payload) => (dispatch) => {
    dispatch({
        type: SEND_OTP_SUCCESS,
        otpPayload: payload
    })
}

const sendOtpFailed = (error) => (dispatch) => {
    dispatch({
        type: SEND_OTP_FAILED,
        otpError: error
    })
}

export const sendOtp = ({ requestBody }) => (dispatch, getState) => {
    return axios.post(`${config.base_url}/auth/sendotp`,
            requestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            if (response.data.status === "success") {
                dispatch(sendOtpSuccess(response.data));
            } else {
                dispatch(sendOtpFailed(response.data));
            }
            return Promise.resolve(getState());
        }).catch((error) => {
            dispatch(sendOtpFailed(error));
            return Promise.resolve(getState());
        });
}

export const otpSuccess = (payload) => (dispatch) => {
    dispatch({
        type: OTP_SUCCESS,
        payload: payload
    })
}

export const otpFailed = (error) => (dispatch) => {
    dispatch({
        type: OTP_FAILED,
        error
    })
}

export const validateOtp = ({ requestBody }) => (dispatch, getState) => {
    return axios.post(`${config.base_url}/auth/validateotp`,
            requestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                }
            }
        ).then((response) => {
            if (response.data.code === 200) {
                dispatch(otpSuccess(response.data));
            } else {
                dispatch(otpFailed(response.data));
            }
            return Promise.resolve(getState());
        }).catch((error) => {
            dispatch(otpFailed(error));
            return Promise.resolve(getState());
        });
};
