import { combineReducers } from 'redux';
import loginReducer from "./loginReducer";
import OtpReducer from "./otpReducer";
import SignUpReducer from "./signUpreducer";
import customerReducer from "./customerReducer";

export default combineReducers({
    login: loginReducer,
    otp:OtpReducer,
    signUp: SignUpReducer,
    customer: customerReducer
});