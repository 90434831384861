export const config = {
    private_key: "-----BEGIN RSA PRIVATE KEY----- MIIEpAIBAAKCAQEAiytY32kjyqRy1bfkgjq97D+DHj8+az5MCvGjPR4XjzYt8Y6C h8s5FMErjpv0AowkmNH0TFPkIrryd6K04391q5znwbA+rXaE8APFUVUQkj9g9+TR Cr4AIuUwZ5aX4kimQh75aQs+yrX/Ot1yH3KhHnzu2GYY6V2+Aigfo7nLJNIBu8hR tjaxlAp7jR09et/d3qp4bLPfJeugpkH9YvUCYwwnTHIfa3rHroDBFHefRcl6+r2d pi3z19Q8dOkG5nFF2uiI4qmv3rnxgbeYQjbHDZagGzvfwUwXDLGhz5+D13uIEaVJ VRx1M5ZifsdbAQA4uTzAi5L0sif5L8e6I4YUWQIDAQABAoIBAA22tck+GRLM5av9 tPzIQRLYp1kBrmru9ptVeFp7bqLkGd7lxiMPeY58AojJAWCG4sxUVBh5Y8+wUFhN pjmMw9GnYocJkQOS3EKyfG0pTrq6mBmVaKwAUQ8fOofapRjdQ9TcLIOXYhajnIT2 cVp79ziD4x1b4jSM0j5wKb3+fvAfxFYcRHTMyDEL2uNa3yaZjsSQlYBKVcoyM87o TLUZFcIpbG7FieXOpKv9DSJ6AUFecZmga3o13/RtjBeA0x9GkXdn7z9GSg/Vijjn sYUiTed/CLNHTGLqaRyif5kH6/oiECOrcuvJYPNAWktE1ddA2oQ8OkYYRskljU1y cpVaVckCgYEAyj+2Xz8KZAxlzUIMUY90FgA0NMlIkscq74f/SFybpIvMdLUgXaDh pehvgtPzgYR1cyOoRCbSofpofEnFyOCPNQfs4YFUH+bXOtzAgkKApMCMiRzacEAb VEmMsstWDA3awkzH1CenwuljQ0D+6/C032pCbLUupm0hYWvNkYEkaA8CgYEAsCft sWmRHBCjz7O6KuPcdv87HhqBpwwP+SQfhXrSZT5tLoAXTMw93CO1tTFoTI5ctpLm h51FgsUFnY9kd1brybWsMBfHhCaE14dRNlAcIM1wMlVaO/aTJebqaija1It4P7bt h0i3gGWUVtUqdgly1xXJT7O5VwNyUY/R4exQlRcCgYEAqMZWSFFxOGBoK8usP/u4 Ygu1hT9bgrEWEWGqFRbDA6Nt8Q6dfcSdyTeYptJ86F5DLNxatNS4/VbcDqv5k1Jh 7103vk+Qhja68XH6bDlW5SWRoXIkj3vP0py1/xZULib5oR81usOE6dCddygsE3SB W9duqwzfax6ytnuhxoTAft0CgYEAkoDSrW830RiBtJcsKMmTLKB+oT24QJ7GlhUV 5iRM4Qj3Muoy9psSg4hrcgNiU/E+aFVqRrVk/youzmhYTOxMc1iVmPXd6ClE6B9R SpN7oUhanHHh9ubOYIJYyOure4N3Ab54c7/lZXKV2VW1169qBDnJUHEPnq9uHyk2 i+fG6QcCgYBIWEiQeEnxI7W3iJzIx2YHqUyd1VD+gboIiAyt7Gw8+JAvxE3RnDAk QNU5Y+/doWQTCFcwLmXtkcx407AsrYUjUJl1a5JOkbSV3UwPbmCNq1Y9O8WGBMLx BPSQdeCRcj4qGw/kzrgu3fehhVkwGlGCVGIq6WadYQmlSF/8AO9vJw== -----END RSA PRIVATE KEY-----",
    // Live Credentials.
    caring_connect:"https://connect.caring2u.com",
    base_url: "https://connectapi.caring2u.com/api/v1",
    domain:".caring2u.com",

    // DEV/UAT Credentials.
    // caring_connect:"http://caringconnect.spurtreetech.com",
    // base_url:"http://caringconnectapi.spurtreetech.com/api/v1",
    // domain: ".spurtreetech.com",
    //base_url: "http://localhost:9000/api/v1",
    assets_base_url:"https://storage.googleapis.com/caring_assets"
};
export default config; 