import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { login } from "../actions/login";
import { sendOtp, validateOtp } from '../actions/otp';
import { getCustomer } from "../actions/customer";
import { parse } from 'query-string';
import { getCookie } from "../utils/Cookie";
import { authenticationCheck } from "../actions/login"
import Notifications, { notify } from 'react-notify-toast';
import validator from "validator";
import Loader from "../common/Loader";
import config from "../config";
var md5 = require('md5');

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryCode: "60",
            email: "",
            password: "",
            mobile: "",
            otpCheck: false,
            otpValue: "",
            errors: {},
            sendingOtp: false,
            staffLoginErrors: {},
            authenticationChecking: true,
            isLoging: false,
            isSendingOtp: false,
            isValidationOtp: false,
            validatebutton: false
        }
    }

    componentWillMount() {
        const { redirect_url, client_code, is_staff, target_client_code, token } = parse(this.props.location.search);
        let hash = this.props.location.hash ? this.props.location.hash : "";
        let url = `${redirect_url}${hash}`;
        localStorage.clear()
        // if (redirect_url) {
        //     localStorage.setItem("redirect_url", url);
        // }
        // if (client_code) {
        //     localStorage.setItem("client_code", client_code);
        // }
        // if (is_staff) {
        //     localStorage.setItem("is_staff", is_staff);
        // }
        //this.setState({ authenticationChecking: false });
        let that = this
        this.props.authenticationCheck({ token: getCookie("token"), client_code: getCookie("client_code") ? getCookie("client_code") : client_code }).then((response, error) => {
            this.setState({ authenticationChecking: false });
            if (!error) {
                if (response.result.isValid) {
                    if (target_client_code) {
                        console.log("in target client code ::::::")
                        let session_id = getCookie("SID")
                        this.props.history.replace({
                            pathname: '/confirmation/',
                            search: `?token=${token}&session_id=${session_id}&is_staff=${is_staff}&client_code=${client_code}&redirect_url=${redirect_url}&target_client_code=${target_client_code}`,
                        });

                    }
                    else {


                        if (client_code != getCookie("client_code")) {
                            let token = getCookie("token")
                            let cookie_client_code = getCookie("client_code")
                            let session_id = getCookie("SID")
                            this.props.history.replace({
                                pathname: '/confirmation/',
                                search: `?token=${token}&session_id=${session_id}&is_staff=${is_staff}&client_code=${cookie_client_code}&redirect_url=${redirect_url}&target_client_code=${client_code}`,
                            });
                        } else {
                            notify.show("Already logged in !!", "success", 3000, "green")
                            setTimeout(() => {
                                window.open(redirect_url, '_self');
                            }, 1000);

                        }

                    }

                } else {
                    this.setState({ authenticationChecking: false });


                }
            } else {
                notify.show("Something went wrong while getting application details", "error", 3000, "red")

                this.setState({ authenticationChecking: false });


            }
        })
    }

    _login = () => {
        let errors = {};
        const { redirect_url, client_code, is_staff, target_client_code, token } = parse(this.props.location.search);
        if (!this.state.email) {
            errors.email = "Username is required";
        } else if (!validator.isEmail(this.state.email)) {
            errors.email = "Invalid Username";
        }
        if (!this.state.password) {
            errors.password = "Password is required";
        }

        this.setState({ staffLoginErrors: errors });

        if (Object.keys(errors).length === 0) {
            const password = md5(this.state.password);
            let requestBody = {};
            requestBody.email = this.state.email;
            requestBody.password = password;
            requestBody.client_code = client_code;
            this.setState({ isLoging: true });
            this.props.login({ requestBody: requestBody }).then((newState) => {
                this.setState({ isLoging: false });
                if (newState && newState.login.payload) {
                    let response = newState.login.payload;
                    this._setCookieAndNav(response.result, redirect_url);
                } else {
                    notify.show(newState.login.error.message ? newState.login.error.message : "Something went wrong", "error", 3000, "red")
                }
            });
        }
    }

    _sendOTP = () => {
        let errors = {};
        let { redirect_url, client_code, is_staff, target_client_code, token } = parse(this.props.location.search);

        if (!this.state.mobile) {
            errors.mobile = "Mobile is required";
        } else if (!(this.state.mobile.length === 8 || this.state.mobile.length === 9 || this.state.mobile.length === 10)) {
            errors.mobile = "Invalid Phone Number";
        }
        this.setState({ errors });



        if (Object.keys(errors).length === 0) {
            let requestBody = {};
            requestBody.phonenumber = this.state.countryCode + this.state.mobile;
            requestBody.client_code = client_code;
            requestBody.deviceId = "";
            this.setState({ isSendingOtp: true });
            this.props.sendOtp({ requestBody: requestBody }).then((newState) => {
                this.setState({ isSendingOtp: false });
                if (newState && newState.otp.otpPayload) {
                    this.setState({ otpCheck: true });
                } else {
                    notify.show(newState.otp.otpError.message, "error", 3000, "red")
                }
            });
        }
    }

    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    handlePasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }

    handleMobileChange = (e) => {
        let errors = {};
        if (e.target.value > 0 || e.target.value == "") {
            this.setState({ mobile: e.target.value.replace(/^0+/, ''), errors: {} });
        } else {
            errors.mobile = "Mobile Number is Invalid";
            this.setState({ errors });

        }

    }

    handleCountryCodeChange = (e) => {
        this.setState({ countryCode: e.target.value });


    }

    handleOTPChange = (e) => {
        this.setState({ otpValue: e.target.value });
    }

    _validateOTP = () => {
        const otpValue = this.state.otpValue
        const { redirect_url, client_code, is_staff, target_client_code, token } = parse(this.props.location.search);

        // let client_code = localStorage.getItem("client_code");
        // let redirect_url = localStorage.getItem("redirect_url");
        // let is_staff = localStorage.getItem("is_staff");
        let requestBody = {};
        requestBody.phonenumber = this.state.countryCode + this.state.mobile;
        requestBody.deviceId = "";
        requestBody.client_code = client_code;
        requestBody.otp = otpValue;
        this.setState({ isValidationOtp: true });
        this.props.validateOtp({ requestBody: requestBody }).then((newState) => {
            if (newState && newState.otp.payload) {
                let response = newState.otp.payload;
                this.setState({ validatebutton: true })
                this.props.getCustomer({ token: response.result.token, client_code }).then((customerResponse) => {
                    this.setState({ isValidationOtp: false });
                    if (customerResponse) {
                        if (customerResponse.code === 200) {
                            //-------- If no email id --------- //
                            console.log("customerResponse::", customerResponse.result.response.customers.customer[0])
                            if (!customerResponse.result.response.customers.customer[0].email || customerResponse.result.response.customers.customer[0].email == "") {
                                this.props.history.replace({
                                    pathname: '/signup/',
                                    search: `?is_staff=${is_staff}&client_code=${client_code}&redirect_url=${redirect_url}`,
                                    state: {
                                        is_registration: false,
                                        mobile: this.state.countryCode + this.state.mobile,
                                        firstname: customerResponse.result.response.customers.customer[0].firstname,
                                        lastname: customerResponse.result.response.customers.customer[0].lastname,
                                        email: customerResponse.result.response.customers.customer[0].email
                                    }
                                });

                            } else {
                                console.log("in else needs to redirect")
                                this._setCookieAndNav(response.result, redirect_url);

                            }


                        } else if (customerResponse.code === 204) {
                            this.props.history.replace({
                                pathname: '/signup/',
                                search: `?is_staff=${is_staff}&client_code=${client_code}&redirect_url=${redirect_url}`,
                                state: { is_registration: true, mobile: this.state.countryCode + this.state.mobile, firstname: "", lastname: "", email: "" }
                            });
                        } else if (customerResponse.code === 401) {
                            this.setState({ isValidationOtp: false });
                            notify.show(customerResponse.messsage, "error", 3000, "red")
                        } else {
                            this.setState({ isValidationOtp: false });
                            notify.show(customerResponse.messsage, "error", 3000, "red")

                        }
                    } else {
                        this.setState({ isValidationOtp: false });
                        notify.show("Something went wrong while checking customer", "error", 3000, "red")
                    }
                }, (error) => {
                    this.setState({ isValidationOtp: false });
                    notify.show(error, "error", 3000, "red")


                });
            } else {
                this.setState({ isValidationOtp: false });
                notify.show(newState.otp.error.message, "error", 3000, "red")
            }
        });
    }

    _setCookieAndNav = (result, redirect_url) => {
        const { client_code } = parse(this.props.location.search);

        var cookieName = 'token';
        var cookieValue = result.token;
        var date = new Date();
        let cookieClient_code = "client_code";
        let cookieClient_code_value = client_code;
        let cookieSID = "SID";
        let cookieSIDValue = result.session_id;
        date.setMonth(date.getMonth() + 12);
        document.cookie = cookieName + "=" + cookieValue + ";expires=" + date
            + ";domain=" + config.domain + ";path=/";
        document.cookie = cookieClient_code + "=" + cookieClient_code_value + ";expires=" + date
            + ";domain=" + config.domain + ";path=/";
        document.cookie = cookieSID + "=" + cookieSIDValue + ";expires=" + date
            + ";domain=" + config.domain + ";path=/";
        //----------- Setting up local server -------------- //
        let subdomain = redirect_url.split("//")[1].split("/")
        //console.log("subdomain::::::",subdomain)
        document.cookie = cookieName + "=" + cookieValue + ";expires=" + date
            + ";domain=" + subdomain[0] + ";path=/";
        document.cookie = cookieClient_code + "=" + cookieClient_code_value + ";expires=" + date
            + ";domain=" + subdomain[0] + ";path=/";

        window.open(redirect_url, '_self');
    }

    render() {
        const { handleSubmit, authenticationCheckSuccess } = this.props;
        const { is_staff } = parse(this.props.location.search);

        const staff_login = is_staff ? is_staff == 1 ? true : false : false;
        return (
            <div className="formWidget">
                <Notifications />
                <article>
                    <div className="row">
                        <div className="col-8">
                        <h2><span>Member Login</span></h2>
                            {/* {!staff_login && <h2>{authenticationCheckSuccess ? `${authenticationCheckSuccess.result.name} - Member Login` : "Member Login"}</h2>}
                            {staff_login && <h2>{authenticationCheckSuccess ? `${authenticationCheckSuccess.result.name} - Admin Login` : "Admin Login"}</h2>} */}

                        </div>

                        <div className="col-4 reg">
                            <img src="https://storage.googleapis.com/caring_api/664610ac-5460-4b43-b9b6-b754021c37cb06280312556c22b9781f1ed3e68ef8c0.png" />
                        </div>
                    </div>
                    {/* <h2><span>Member Login</span></h2> */}

                    {/* <h4>welcome to</h4>
                    <h2>{authenticationCheckSuccess ? `${authenticationCheckSuccess.result.name}` : ""}</h2> */}
                </article>
                {staff_login && <form onSubmit={handleSubmit(this._login)}>
                    <div className="form-group">
                        <label>username</label>
                        <input type="email" name="username" placeholder="user name" className="form-control" onChange={this.handleEmailChange} />
                    </div>
                    {this.state.staffLoginErrors && this.state.staffLoginErrors.email && <span className="validationError">{this.state.staffLoginErrors.email}</span>}

                    <div className="form-group">
                        <label>password</label>
                        <input type="password" name="password" placeholder="password" className="form-control" onChange={this.handlePasswordChange} />
                    </div>
                    {this.state.staffLoginErrors && this.state.staffLoginErrors.password && <span className="validationError">{this.state.staffLoginErrors.password}</span>}
                    <div className="form-group">
                        <button type="submit" className="btn btn-default">login</button>
                    </div>
                </form>}
                {!staff_login && !this.state.otpCheck && <form onSubmit={handleSubmit(this._sendOTP)}>
                    <div className="form-group pr">
                        <label>Mobile</label>
                        <select className="form-control" onChange={this.handleCountryCodeChange} value={this.state.countryCode}>
                            <option value="60">+60</option>
                            <option value="62">+62</option>
                            <option value="65">+65</option>
                        </select><input type="number" name="mobile" placeholder="Enter Mobile Number" className="form-control custPadding" onChange={this.handleMobileChange} />
                    </div>
                    {this.state.errors && this.state.errors.mobile && <span className="validationError">{this.state.errors.mobile}</span>}
                    <div className="form-group sendOtp">
                        <button type="submit" className="btn btn-default">SEND OTP (ONE-TIME PASSWORD)</button>
                    </div>
                </form>}
                {!staff_login && this.state.otpCheck && <form onSubmit={handleSubmit(this._validateOTP)}>
                    <div className="form-group">
                        <label>OTP</label>
                        <input type="number" name="opt" placeholder="Enter OTP" className="form-control" onChange={this.handleOTPChange} />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-default" disabled={this.state.validatebutton}>Validate OTP</button>
                    </div>
                </form>}
                <Loader loader={this.state.authenticationChecking} />
                <Loader loader={this.state.isLoging} />
                <Loader loader={this.state.isSendingOtp} />
                <Loader loader={this.state.isValidationOtp} />

                <article className="footer">
                    <h6 className="text-uppercase notMember">not a member?</h6>
                    <p className="text-uppercase"><strong>Download OUR APP NOW</strong></p>
                    <figure>
                        <a href="https://play.google.com/store/apps/details?id=com.capillarytech.caringpharma">
                            <img src="https://storage.googleapis.com/caring_api/d4352ae7-3afa-4e03-85cf-b94c1eb3362e40cb5b57767c42248bdbe5fe094be19c.png" />
                        </a>
                    </figure>
                    <figcaption><a href="https://play.google.com/store/apps/details?id=com.capillarytech.caringpharma">https://play.google.com/store/apps/details?id=com.capillarytech.caringpharma</a></figcaption>
                    <figure>
                        <a href="https://itunes.apple.com/in/app/caring-pharmacy/id1319815592?mt=8">
                            <img src="https://storage.googleapis.com/caring_api/1ada9fa4-438d-4c84-8119-bd4a60db6ff1b6c7dcece73f181692ba56a3fe1660e3.png" />
                        </a>
                    </figure>
                    <figcaption><a href="https://itunes.apple.com/in/app/caring-pharmacy/id1319815592?mt=8">https://itunes.apple.com/in/app/caring-pharmacy/id1319815592?mt=8</a></figcaption>
                    <figure>
                        <a href="https://appgallery.cloud.huawei.com/marketshare/app/C101199723?locale=en_GB&source=appshare&subsource=C101199723">
                            <img src="https://storage.googleapis.com/caring_api/Huawei_logo.png" />
                        </a>
                    </figure>
                    <figcaption><a href="https://appgallery.cloud.huawei.com/marketshare/app/C101199723?locale=en_GB&source=appshare&subsource=C101199723">https://appgallery.cloud.huawei.com/marketshare/app/C101199723?locale=en_GB&source=appshare&subsource=C101199723</a></figcaption>
                    <p className="text-uppercase m0"><strong>or</strong></p>
                    <p className="text-uppercase m0"><strong>visit our nearest store to register now</strong></p>
                    <h6 className="text-uppercase">more info about our member's benefits </h6>
                </article>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        payload: state.login.payload,
        otpPayload: state.otp.otpPayload,
        authenticationCheckSuccess: state.login.authenticationCheckSuccess
    }
}

const enhance = compose(
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            if (!values.username) {
                errors.username = "User name is required";
            }
            if (!values.password) {
                errors.password = "Password is required";
            }
            return errors;
        },
    }),
    connect(
        mapStateToProps,
        { login, validateOtp, sendOtp, getCustomer, authenticationCheck }
    )
);

export default enhance(Login);