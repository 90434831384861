import React from "react";

const Loader = props => {
    const { loader } = props;
    if (loader) {
        return (
            <section className="loadingSec">
                <div className="loader"></div>
            </section>
        );
    }
    return null;
}

export default Loader;