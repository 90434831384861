import React from "react";
import { getCookie } from "../utils/Cookie";
import config from "../config"; 
import { parse } from 'query-string';
import { decrypt, isValidToken } from "../utils/Decrypt";
import { logoutUser } from "../actions/login";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Notifications, {notify} from 'react-notify-toast';



class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
    }

    _resetCookie = (redirect_url) => {
       

        
    }

    componentWillMount() {
        let token = getCookie("token");
        let client_code = getCookie("client_code");
        let session_id = getCookie("SID");
        const { redirect_url } = parse(this.props.location.search);

        this.props.logoutUser({token,client_code,session_id}).then(function(data){
            console.log("data:::",data)
            console.log("redirect_url",redirect_url)
            document.cookie = "token" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;  
            document.cookie = "client_code" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;  
            document.cookie = "SID" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;  

            window.open(redirect_url,'_self');
        },function(error){
            console.log(error,"error")
            console.log("redirect_url",redirect_url)  
            document.cookie = "token" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;  
            document.cookie = "client_code" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;  
            document.cookie = "SID" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;  
            window.open(redirect_url,'_self');


        })



        
    }

    _redirect = () => {
        const { redirect_url, client_code} = parse(this.props.location.search);
        this.props.history.replace(`/?is_staff=1&client_code=${client_code}&redirect_url=${redirect_url}`);
    }

 

   
    
    render() {
      

        return ( 
            <div className="formWidget row">
                <h4>Please Wait...</h4>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        logoutCheck: state.login.payload
    }   
}

const enhance = compose(
    connect(
        mapStateToProps,
        { logoutUser }
    )
);

export default enhance(LogoutPage);

