import React from "react";
import { getCookie } from "../utils/Cookie";
import config from "../config"; 
import { parse } from 'query-string';
import { decrypt, isValidToken } from "../utils/Decrypt";
import { authenticationCheck,getTargetIdTokenCheck,getTargetIdTokenCheckForCRM } from "../actions/login";
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Notifications, {notify} from 'react-notify-toast';



class ConfirmationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           userDetails:{
               "first_name":"",
               "last_name":"",
               "phonenumber":""
           }
        }
    }

    _setCookieAndNav = (result, redirect_url ) => {
        const { target_client_code} = parse(this.props.location.search);


        var cookieName = 'token';
        var cookieValue = result.token;
        var date = new Date();
        let cookieClient_code = "client_code";
        let cookieClient_code_value = target_client_code;
        let cookieSID = "SID";
        let cookieSIDValue = result.session_id;
        date.setMonth(date.getMonth() + 12);
        document.cookie = cookieName +"=" + cookieValue + ";expires=" + date 
                        + ";domain="+config.domain+";path=/";
        document.cookie = cookieClient_code +"=" + cookieClient_code_value + ";expires=" + date 
                        + ";domain="+config.domain+";path=/";
        document.cookie = cookieSID +"=" + cookieSIDValue + ";expires=" + date 
                        + ";domain="+config.domain+";path=/";

        window.open(redirect_url,'_self');
        
    }

    componentWillMount() {
        let token = getCookie("token");
        const { redirect_url, client_code,is_staff,target_client_code} = parse(this.props.location.search);
        if(is_staff == 0 || is_staff == "0"){
            this._CRMcontinue() 

        }
        
        this.props.authenticationCheck({token: getCookie("token"), client_code: getCookie("client_code")?getCookie("client_code"):client_code }).then((response, error) => {
            if (!error){
                if (response.result.isValid) {
                    this.setState({ userDetails: response.result });

                }else{
                    //window.open(`${config.caring_connect}/#/?is_staff=${is_staff}&client_code=${client_code}&redirect_url=${redirect_url}`, "_self");

                }
            } 
        })
    }

    _redirect = () => {
        const { redirect_url, client_code} = parse(this.props.location.search);
        this.props.history.replace(`/?is_staff=1&client_code=${client_code}&redirect_url=${redirect_url}`);
    }

    _CRMcontinue() {
        let that = this;
        const { redirect_url, client_code,is_staff,target_client_code,session_id,token} = parse(this.props.location.search);
        //---------- Call api and replace new token and redirect------------ //
        console.log("token:::",token,redirect_url)

        if(token && token != null && token != "null"){
            this.props.getTargetIdTokenCheckForCRM({token: token, client_code:client_code,target_client_code:target_client_code,session_id:session_id }).then(function(response){
                if(response.code == 200){
                   that._setCookieAndNav(response.result,redirect_url)      
                }else{
                   notify.show(response.message, "error", 3000, "red")
                   setTimeout(() => {
                    that.props.history.replace(`/?is_staff=${is_staff}&client_code=${target_client_code}&redirect_url=${redirect_url}`);                       
                   }, 2000);

               }
           },function(error){

               notify.show(error.message, "error", 3000, "red")
               setTimeout(() => {
                that.props.history.replace(`/?is_staff=${is_staff}&client_code=${target_client_code}&redirect_url=${redirect_url}`);                       
               }, 2000);
   
           })

        }else{

            window.open(redirect_url,'_self');
        }
       
        
    }

    _continue() {
        let that = this;
        const { redirect_url, client_code,is_staff,target_client_code,session_id} = parse(this.props.location.search);
        //---------- Call api and replace new token and redirect------------ //
        this.props.getTargetIdTokenCheck({token: getCookie("token"), client_code:getCookie("client_code")?getCookie("client_code"):client_code,target_client_code:target_client_code,session_id:session_id }).then(function(response){
             if(response.code == 200){
                that._setCookieAndNav(response.result,redirect_url)      
             }else{
                notify.show(response.message, "error", 3000, "red")
            }
        },function(error){
            notify.show(error.message, "error", 3000, "red")

        })
        
    }

    _diffAccountLogin() {
        document.cookie = "token" +`=;domain=${config.domain};path=/; Expires=Thu, ${new Date() - 1};`;
        const { redirect_url, client_code,is_staff,target_client_code,session_id} = parse(this.props.location.search);
        window.open(`${config.caring_connect}/#/?is_staff=${is_staff}&client_code=${target_client_code}&redirect_url=${redirect_url}`, "_self");
    }
    
    render() {
      
        let {is_staff} = parse(this.props.location.search);
        return ( 
            
                <div>
                {(is_staff==1 || is_staff == "1") && <div className="formWidget row"> <Notifications/><div className="form-group">
                    <button onClick={this._continue.bind(this)} className="btn btn-default DefaultBtn">{ this.state.userDetails.first_name ? `Continue as ${this.state.userDetails.first_name}`: this.state.userDetails.phonenumber ? `Continue as ${this.state.userDetails.phonenumber}`:""}</button>
                </div>
                <a href="javascript:;" onClick={this._diffAccountLogin.bind(this)} className="linkBtn">Or SignIn with different account</a></div>}
                {(is_staff==0 || is_staff == "0") &&  <div className="formWidget row"><Notifications/>
                <h4>Please Wait...</h4>
            </div>}
            </div>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticationCheckSuccess: state.login.authenticationCheckSuccess,
        targetIdToken: state.login.payload
    }   
}

const enhance = compose(
    connect(
        mapStateToProps,
        { authenticationCheck,getTargetIdTokenCheck,getTargetIdTokenCheckForCRM }
    )
);

export default enhance(ConfirmationPage);

