import { OTP_SUCCESS, OTP_FAILED, SEND_OTP_SUCCESS, SEND_OTP_FAILED } from "../constants/ActionTypes";

const INITIAL_STATE = { otpPlayload: "", otpError: "", payload: "", error: "" };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEND_OTP_SUCCESS: {
            return { ...state, otpPayload: action.otpPayload };
        }
        case SEND_OTP_FAILED: {
            return { ...state, otpError: action.otpError };
        }
        case OTP_SUCCESS: {
            return {...state, payload: action.payload }
        }
        case OTP_FAILED: {
            return {...state, error: action.error }
        }
        default:
            return state
    }
}
