export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILED = "OTP_FAILED";
export const SIGNUP_SUCCES = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";
export const CUSTOMER_FETCH_SUCCESS = "CUSTOMER_FETCH_SUCCESS";
export const CUSTOMER_FETCH_FAILED = "CUSTOMER_FETCH_FAILED";
export const CHECK_CUSTOMER_SUCCESS = "CHECK_CUSTOMER_SUCCESS";
export const CHECK_CUSTOMER_FAILED = "CHECK_CUSTOMER_FAILED";
export const AUTHENTICATION_CHECK_SUCCESS = "AUTHENTICATION_CHECK_SUCCESS";
export const AUTHENTICATION_CHECK_FAILED = "AUTHENTICATION_CHECK_FAILED";
