import { SIGNUP_SUCCES, SIGNUP_FAILED } from "../constants/ActionTypes";

const INITIAL_STATE = { payload: "", error: "" };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNUP_SUCCES: {
            return {...state, payload: action.payload }
        }
        case SIGNUP_FAILED: {
            return {...state, error: action.payload }
        }
        default:
            return state
    }
}