import { CUSTOMER_FETCH_SUCCESS, CUSTOMER_FETCH_FAILED, CHECK_CUSTOMER_SUCCESS, CHECK_CUSTOMER_FAILED } from "../constants/ActionTypes";

const INITIAL_STATE = { customerDetails: "", error: "", checkCustomer: "", checkCustomerError: ""};

export default(state = INITIAL_STATE, action) => {
    switch(action.type) {
        case CUSTOMER_FETCH_SUCCESS: {
            return { ...state, customerDetails: action.customer };
        }
        case CUSTOMER_FETCH_FAILED: {
            return {...state, error: action.error }
        }
        case CHECK_CUSTOMER_SUCCESS: {
            return { ...state, checkCustomer: action.checkCustomer };
        }
        case CHECK_CUSTOMER_FAILED: {
            return {...state, checkCustomerError: action.checkCustomerError }
        }
        default:
            return state;
    }
}