import { SIGNUP_SUCCES, SIGNUP_FAILED } from "../constants/ActionTypes";
import config from "../config";
import axios from "axios";

export const signUpSuccess = (payload) => (dispatch) => {
    dispatch({
        type: SIGNUP_SUCCES,
        payload: payload
    })
}

export const signUpFailed = () => (dispatch) => {
    dispatch({
        type: SIGNUP_FAILED,
        payload: "FAILED"
    })
}

export const signUp = ({ token, client_code, requestBody }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.base_url}/customer/register`,
        requestBody,
        {
            headers: {
                "Content-Type": "application/json",
                "x-user-token": token,
                "client_code": client_code
            }
        }
        ).then((response) => {
            if (response.data.code === 200) {
                dispatch(signUpSuccess(response.data));
                resolve(response.data);
            } else {
                dispatch(signUpFailed(response.data));
                reject(response.data);
            }
        }).catch((error) => {
            dispatch(signUpFailed(error));
            reject(error);
        });
    })
};
