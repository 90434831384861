import { LOGIN_SUCCESS, LOGIN_FAILED, AUTHENTICATION_CHECK_SUCCESS, AUTHENTICATION_CHECK_FAILED } from "../constants/ActionTypes";
import config from "../config";
import axios from "axios";

export const loginSuccess = (payload) => (dispatch) => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload
    })
}

export const loginFailed = (error) => (dispatch) => {
    dispatch({
        type: LOGIN_FAILED,
        error
    })
}

export const login = ({ requestBody }) => (dispatch, getState) => {
    return axios.post(`${config.base_url}/authentication/login`,
            requestBody,
			{
				headers: {
					"Content-Type": "application/json",
				}
			}
		).then((response) => {
            if (response.data.code === 200) {
                dispatch(loginSuccess(response.data));
            } else {
                dispatch(loginFailed(response.data));
            }
            return Promise.resolve(getState());
		}).catch((error) => {
            dispatch(loginFailed(error));
            return Promise.resolve(getState());
		});
};

const authenticationCheckSuccess = (payload) => (dispatch) => {
    dispatch({ 
        type: AUTHENTICATION_CHECK_SUCCESS,
        authenticationCheckSuccess: payload
    })
}

const authenticationCheckFailed = (error) => (dispatch) => {
    dispatch({ 
        type: AUTHENTICATION_CHECK_SUCCESS,
        authenticationCheckError: error
    })
}

export const authenticationCheck = ({ token, client_code }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.base_url}/authentication/applicationcheck`,
			{
				headers: {
                    "x-user-token": token,
                    "client_code": client_code
				}
			}
		).then((response) => {
            if (response.data.code === 200) {
                dispatch(authenticationCheckSuccess(response.data));
                resolve(response.data);
            } else {
                dispatch(authenticationCheckFailed(response.data));
                reject(response.data);
            }
		}).catch((error) => {
            dispatch(authenticationCheckFailed(error));
            reject(error);
		});
    });
};

export const getTargetIdTokenCheckForCRM = ({ token, client_code,target_client_code,session_id }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.base_url}/crmsignon`,{"target_client_code":target_client_code,"session_id":session_id},
			{
				headers: {
                    "x-user-token": token,
                    "client_code": client_code
				}
			}
		).then((response) => {
            console.log("response:::",response)
            if (response.data.code === 200) {
                dispatch(loginSuccess(response.data));
                console.log("response whileeee:::",response.data)
                resolve(response.data);
            } else {
                dispatch(loginFailed(response.data));
                reject(response.data);
            }
		}).catch((error) => {
            dispatch(loginFailed(error));
            reject(error);
		});
    });
};

export const getTargetIdTokenCheck = ({ token, client_code,target_client_code,session_id }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.base_url}/connectsignon`,{"target_client_code":target_client_code,"session_id":session_id},
			{
				headers: {
                    "x-user-token": token,
                    "client_code": client_code
				}
			}
		).then((response) => {
            console.log("response:::",response)
            if (response.data.code === 200) {
                dispatch(loginSuccess(response.data));
                console.log("response whileeee:::",response.data)
                resolve(response.data);
            } else {
                dispatch(loginFailed(response.data));
                reject(response.data);
            }
		}).catch((error) => {
            dispatch(loginFailed(error));
            reject(error);
		});
    });
};

export const logoutUser = ({ token,client_code,session_id}) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.base_url}/customer/logout`,
			{
				headers: {
                    "x-user-token": token,
                    "client_code": client_code,
                    "session_id":session_id
				}
			}
		).then((response) => {
            if (response.data.code === 200) {
                dispatch(loginSuccess(response.data));
                resolve(response.data);
            } else {
                dispatch(loginFailed(response.data));
                reject(response.data);
            }
		}).catch((error) => {
            dispatch(loginFailed(error));
            reject(error);
		});
    });
};

