import React, { Component } from 'react';
import { HashRouter, Route } from "react-router-dom";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ConfirmationPage from "./components/ConfimationPage";
import LogoutPage from "./components/LogoutPage";


class App extends Component {
    render() {
      return (
        <HashRouter>
          <div>
            <Route exact path = "/" component = {Login}/>
            <Route exact path = "/signup" component = {SignUp}/>
            <Route exact path = "/confirmation" component = {ConfirmationPage}/>
            <Route exact path = "/logout" component = {LogoutPage}/>

          </div>
        </HashRouter>
      );
    }
}

export default App;