import config from "../config"
export function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}


export function setCookie(name,value) {
    var date = new Date();
    document.cookie = name +"=" + value + ";expires=" + date 
    + ";domain="+config.domain+";path=/";

}